import React, {FC, useState} from 'react';
import asyncModal from "react-async-modal";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {ReactSVG} from "react-svg";
import close from "../assets/icons/close.svg";
import girl from "./assets/images/girl.jpg";
import {thumb} from "../modules/utils";
import HomeStore from "../store/HomeStore";
import {AuthRecovery, AuthSign} from "../pages/AuthPage";
import useBackButtonMobile from "../hooks/useBackButtonMobile";


interface Props {
  resolve(success: boolean | number): void;
  defaultType?: 'login'|'register';
  defaultImageId?: string | null;
  modelIdConversation?: string;
}

const ModalAuth: FC<Props> = ({defaultType, defaultImageId, modelIdConversation,resolve}) => {
  useBackButtonMobile(handleClose);

  const [modalType, setModalType] = useState<'login'|'register'|'recovery'>(defaultType || 'login');


  function handleClose() {
    resolve(false)
  }

  return (
    <div className='modal-container big modal-with_img'>
      <ReactSVG src={close} className='modal-close' onClick={handleClose}/>
      <div className="modal-with_img-image">
        <img src={thumb(defaultImageId || HomeStore.randomModels?.[0]?.mainPhotoImage?.id, 800) || girl} alt="girl"/>
      </div>

      <div className='modal-with_img-content'>

        {modalType === 'recovery'
          ?
          <AuthRecovery onTypeChange={() => setModalType('register')}/>
          :
          <GoogleOAuthProvider
            clientId='709075734698-c1s86vjvosjre4ag8q46b0o6nk8g33h7.apps.googleusercontent.com'>
            <AuthSign
              modelIdConversation={modelIdConversation}
              authType={modalType}
              onTypeChange={setModalType}
              onFinish={resolve}
            />
          </GoogleOAuthProvider>
        }
      </div>
    </div>
  );
}

const openModalLogin = (defaultType?: 'login'|'register', defaultImageId?: string | null, modelIdConversation?: number): Promise<boolean> => {
  return asyncModal((props) => <ModalAuth defaultType={defaultType} defaultImageId={defaultImageId} modelIdConversation={modelIdConversation} {...props}/>, {}, {
    showCloseIcon: false,
    center: true,
    blockScroll: true
  });
};

export {openModalLogin};