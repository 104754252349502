import {useEffect} from 'react';


const useBackButtonMobile = (handleClose: () => void) => {
  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      handleClose();
    };
    window.addEventListener("popstate", handleBeforeUnload);
    return () => {
      window.removeEventListener("popstate", handleBeforeUnload);
    };
  }, []);
}

export default useBackButtonMobile;