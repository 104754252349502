import asyncModal from 'react-async-modal';
import Button from '../../components/Button';
import {ReactSVG} from "react-svg";
import React, {FC, useState} from "react";
import close_svg from "../../assets/icons/close.svg";
import {observer} from "mobx-react";
import {Select} from "../../components/FormControls";
import {useTranslation} from "react-i18next";
import {availableLanguages} from "../../i18n";
import {getLanguageName} from "../../modules/utils";
import moment from "moment";
import AppStore from "../../store/AppStore";
import {API} from "../../modules/api";
import {ELanguage} from "../../modules/rest";
import {toast} from "react-toastify";
import useBackButtonMobile from "../../hooks/useBackButtonMobile";


interface ConfirmModalProps {
  resolve(success?: boolean): void;
}

const LanguageModal: FC<ConfirmModalProps> = observer(({resolve}) => {
  const {t, i18n,} = useTranslation();
  useBackButtonMobile(handleClose);

  const [loading, setLoading] = useState(false);
  const [lang, setLang] = useState(i18n.language);

  const submit = async (e: any) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    try {
      localStorage.setItem('ln', lang);
      moment.locale(lang);
      await i18n.changeLanguage(lang);
      API.setHeadersHandler((headers) => {
        headers['Accept-Language'] = lang;
      });
      await AppStore.updateUser({language: lang as ELanguage});
      await AppStore.getDict();

      handleClose();
    } catch (e: any) {
      toast.error(e);
    } finally {
      setLoading(false)
    }
  }

  function handleClose() {
    resolve()
  }

  return (
    <div className='modal-container modal-gender-settings'>
      <ReactSVG src={close_svg} className='modal-close' onClick={handleClose}/>
      <h4 className='modal-title justify-content-center'>{t('LANGUAGE')}</h4>
      <form onSubmit={submit}>
        <Select
          value={lang}
          onChange={(e) => setLang(e.target.value)}
        >
          {availableLanguages.map(ln => (
            <option value={ln} key={ln}>{getLanguageName(ln)}</option>
          ))}
        </Select>
        <div className="modal-footer sm">
          <Button
            onClick={handleClose}
            title={'CANCEL'}
            btnType='secondary'
          />
          <Button
            loading={loading}
            title={'SAVE'}
            type='submit'
          />
        </div>
      </form>
    </div>
  );
});

const openLanguageModal = (): Promise<void> => {
  return asyncModal(LanguageModal, {}, {showCloseIcon: false, center: true});
};

export {openLanguageModal};
