import asyncModal from 'react-async-modal';
import Button from '../../components/Button';
import {ReactSVG} from "react-svg";
import React, {FC, useState} from "react";
import close_svg from "../../assets/icons/close.svg";
import {EGender} from "../../modules/rest";
import ActiveElement from "../../components/ActiveElement";
import {GenderIcon} from "../../components/GenderSelect";
import {toast} from "react-toastify";
import AppStore from "../../store/AppStore";
import {useTranslation} from "react-i18next";
import useBackButtonMobile from "../../hooks/useBackButtonMobile";


interface ConfirmModalProps {
  gender: EGender|null;

  resolve(gender?: EGender): void;
}

const GenderModal: FC<ConfirmModalProps> = ({gender, resolve}) => {
  const {t} = useTranslation();
  useBackButtonMobile(handleClose);

  const [loading, setLoading] = useState(false);
  const [tempGender, setTempGender] = useState(gender || EGender.Male);

  const submit = async () => {
    try {
      setLoading(true);
      await AppStore.updateUser({gender: tempGender});
      resolve(tempGender);
      toast.success(t('GENDER_UPDATED'));
    } catch (e: any) {
      toast.error(e);
    } finally {
      setLoading(false);
    }

  }

  function handleClose() {
    resolve()
  }

  return (
    <div className='modal-container modal-gender-settings'>
      <ReactSVG src={close_svg} className='modal-close' onClick={handleClose}/>
      <h4 className='modal-title justify-content-center'>{t('GENDER')}</h4>
      <div className='d-flex' style={{margin: '0 -8px'}}>
        {Object.values(EGender).map(gender => (
          <ActiveElement
            key={gender}
            className='w-50 px-2'
            bordered
            radio
            small
            active={tempGender === gender}
            onClick={() => setTempGender(gender)}
          >
            <div className='modal-gender-item'>
              <GenderIcon gender={gender} active={tempGender === gender} size='xl'/>
              <div className='text-bold letter-uppercase mt-4'>{t(gender.toUpperCase())}</div>
            </div>
          </ActiveElement>
        ))}
      </div>
      <div className="modal-footer sm">
        <Button
          onClick={handleClose}
          title={'CANCEL'}
          btnType='secondary'
        />
        <Button
          loading={loading}
          title={'SAVE'}
          onClick={submit}
        />
      </div>
    </div>
  );
};

const openGenderModal = (gender?: EGender|null): Promise<boolean|string> => {
  return asyncModal(GenderModal, {gender}, {showCloseIcon: false, center: true});
};

export {openGenderModal};
