import asyncModal from 'react-async-modal';
import {ReactSVG} from "react-svg";
import React, {FC } from "react";
import close_svg from "../assets/icons/close.svg";
import {observer} from "mobx-react";
import {IModel} from "../modules/rest";
import {ModelProfile} from "../pages/ModelProfilePage";
import useBackButtonMobile from "../hooks/useBackButtonMobile";

interface Props {
  model: IModel;
  resolve(success?: boolean): void;
}

const ModelProfileModal: FC<Props> = observer(({resolve, model}) => {
  useBackButtonMobile(handleClose);

  function handleClose() {
    resolve()
  }

  return (
    <div className='modal-container big'>
      <ReactSVG src={close_svg} className='modal-close' onClick={handleClose}/>
      <ModelProfile model={model} canOpenPhoto={false} />
    </div>
  );
});

const openModelProfileModal = (model: IModel)  => {
  return asyncModal(ModelProfileModal, {model}, {showCloseIcon: false, center: true});
};

export {openModelProfileModal};
